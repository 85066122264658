import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import AwesomeTable from '../../components/Awesome/AweomeTable';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import TradingDialog from './TradingDialog';
import { getDriverTransactions } from '../../data/accounts/actions';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import { BlockRounded, RemoveRedEye } from '@mui/icons-material';
import { deactivateUser, getAcceptedDrivers } from '../../data/users/actions/users.actions';
import { t } from 'i18next';
import AwesomeDataDialog from '../../components/Awesome/AwesomDataDialog';
import Loader from '../../components/Common/Loader';

function AcceptedDrivers() {
  const dispatch = useDispatch();
  const data  = useSelector((state)=> state.users);
  const transactions = useSelector((state)=> state.accounts.transactions);
  const [selectedRow, setSelectedRow] = useState();
  const [openView, setOpenView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedView, setSelectedView] = useState();
  const [openLineView, setOpenLineView] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [acceptedValue, setAcceptedValue] = useState(null);


  useEffect(()=>{
    setIsLoading(true);

    dispatch(getAcceptedDrivers()).then((res)=>{
      setIsLoading(false);

    });
  },[dispatch]);

  useEffect(()=>{
    if (selectedRow != null && selectedRow !== undefined) {
    dispatch(getDriverTransactions(selectedRow)).then((res)=>{
      setOpenView(true)
    });
  }
  },[selectedRow,dispatch]);


  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  const handleAcceptConfirmation = (value) => {
    setAcceptedValue(value);
    setConfirmationOpen(true);
  };

  const setAcceptOptions=()=>{
    dispatch(deactivateUser(acceptedValue))
    .then(() => {
      dispatch(getAcceptedDrivers());
      setConfirmationOpen(false);
    })
    .catch((error) => {
      setConfirmationOpen(false);
    });
  }


  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Name'),
        type: 'text',
        required: false,
        size: 140,
      },
      {
        accessorKey: 'email',
        header: t('Email'),
        type: 'text',
        required: false,
        size: 140,
      },
      {
        accessorKey: 'phone',
        header: t('Phone'),
        type: 'text',
        required: false,
        size: 140,
      },
      {
        accessorKey: 'address',
        header: t('Address'),
        type: 'text',
        required: false,
        size: 140,
      },

   
    ],
    [data.acceptedDrivers],
  );

  console.log()

  const RowActions =(row)=>{
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.2rem' }}>
          <Tooltip arrow placement="left" title={t("Transactions")}>
            <IconButton color="success" variant="contained" onClick={() => {
              
              setSelectedRow(row.original);
              setOpenView(true);
              
              }}>
                <ImportExportIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="left" title={t("Deactivate")}>
            <IconButton color="error" variant="contained" onClick={() => {
              
              handleAcceptConfirmation(row.original);
              setConfirmationOpen(true);
              
              }}>
                <BlockRounded/>
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="left" title={t("View")}>
            <IconButton color="error" variant="contained" onClick={() => {
              
              setSelectedView(row.original);
              setOpenLineView(true);
              
              }}>
                <RemoveRedEye color='success'/>
            </IconButton>
          </Tooltip>
         
       
        </Box>
      </>
    );
  }
  if(isLoading){
    return <Loader/>
  }

  return (
    <>
    <AwesomeTable 
    columns={columns}
    data={data?.acceptedDrivers?.data}
    showActions
    RowActions={RowActions}
    showTopActions ={false}

    />
    {transactions?.data && <TradingDialog
      title={""}
      data = {transactions?.data}
      open={openView}
      onClose={() => {
        setOpenView(false)
        setSelectedRow()
      }}
    />}

{selectedView && <AwesomeDataDialog
      title={selectedView['name']}
      data = {selectedView}
      columns={[  
        { accessorKey: 'name', type: 'text', header: t('Name') },
        { accessorKey: 'email', type: 'text', header: t('Email') },
        { accessorKey: 'phone', type: 'text', header: t('Phone') },
        { accessorKey: 'address', type: 'text', header: t('Address') },
        { accessorKey: 'id_image_url', header: t('IdImage') },
        { accessorKey: 'truck_image', header: t('TruckImage') },
        { accessorKey: 'truck_name',type: 'text', header: t('TruckName') },
        { accessorKey: 'load_by_ton', type: 'text',header: t('LoadByTon') },
        { accessorKey: 'plate_number',type: 'text', header: t('PlateNumber') },
        { accessorKey: 'symbol', type: 'text',header: t('Symbol') },
        { accessorKey: 'governorate',type: 'text', header: t('Governorate') },
      ]}
      open={openLineView}
      onClose={() => {
        setOpenLineView(false)
        setSelectedView()
      }}
    />}

    <ConfirmationDialog
      open={confirmationOpen}
      onClose={handleCloseConfirmation}
      onConfirm={setAcceptOptions}
      message={t("DeactivateRequest")}
      />
      
    </>
  )
}

export default AcceptedDrivers