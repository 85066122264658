import React, { useEffect, useMemo, useState } from 'react'
import AwesomeTable from '../../components/Awesome/AweomeTable'
import { useDispatch, useSelector } from 'react-redux';
import { createTrucks, deleteTruck, editTrucks, getAllTrucks } from '../../data/trucks/actions';
import AwesomeDialog from '../../components/Awesome/AwesomeDialog';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { t } from 'i18next';
import Loader from '../../components/Common/Loader';

function TruckTypes() {
  const dispatch = useDispatch();
  const data  = useSelector((state)=> state.trucks);
  const [editedRow, setEditedRow] = useState();
  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deleteValue, setDeleteValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const  images = process.env.REACT_APP_IMAGES ;

  useEffect(()=>{
    setIsLoading(true);

    dispatch(getAllTrucks()).then((res)=>{
      setIsLoading(false);

    });
  },[dispatch]);

 const handleSubmit =(values)=>{
  dispatch(createTrucks(values))
  .then(() => {
    dispatch(getAllTrucks());
  })
  .catch((error) => {
    // Handle error if needed
  });
 }
 const handleEdit =(values)=>{
  dispatch(editTrucks(values))
  .then(() => {
    dispatch(getAllTrucks());
  })
  .catch((error) => {
    // Handle error if needed
  });
 }
 const setDeleteOptions=()=>{
  dispatch(deleteTruck(deleteValue))
  .then(() => {
    dispatch(getAllTrucks());
    setConfirmationOpen(false);
  })
  .catch((error) => {
    // Handle error if needed
    setConfirmationOpen(false);
  });
}

const handleDeleteConfirmation = (value) => {
  setDeleteValue(value);
  setConfirmationOpen(true);
};
const handleCloseConfirmation = () => {
  setConfirmationOpen(false);
};



  const setCreateOptions=(value)=>{
    setCreateMode(value);
  }
  const setEditOptions=(value)=>{
    setEditedRow(value);
    setEditMode(true);
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Name'),
        type: 'text',
        required:true,
        mapped:true,
        size: 140
      },
  
      {
        accessorFn: (row) => "",
        accessorKey: 'image_url',
        id: 'image_url',
        header: t("Image"),
        
        type: "file",
        mapped:true,
        size: 250,
        required:true,
        Cell: ({ row }) => (
          <img
            alt="avatar"
            style={{ height: '40px', width: '40px' }}
            src={`${images}/${row.original.image_url}`}
            loading="lazy"
          />
        ),
      },
      {
        accessorKey: 'trucks',
        header: t("Trucks"),
        type: 'text',
        mapped:false,
        required:false,
        size: 140
      },
    ],
    [data],
  );
  const RowActions =(row)=>{
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
          <Tooltip arrow placement="left" title={t("Edit")}>
            <IconButton onClick={() => {
              
              setEditOptions(row.original); }}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="right" title={t("Delete")}>
            <IconButton color="error" onClick={() => {
              handleDeleteConfirmation(row.original)}}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      </>
    );
  }

  if(isLoading){
    return <Loader/>
  }
 
  return (
    <>
    <AwesomeTable 
    columns={columns}
    data={data?.data}
    setCreateOptions={setCreateOptions}
    RowActions={RowActions}
    showActions

    />
    <AwesomeDialog
      data = {editedRow}
      columns={columns}
      open={createMode || editMode}
      onClose={() => {
        setCreateMode(false)
        setEditMode(false)
        setEditedRow()
      }}
      onSubmit={editMode? handleEdit: handleSubmit}
    />
      <ConfirmationDialog
      open={confirmationOpen}
      onClose={handleCloseConfirmation}
      onConfirm={setDeleteOptions}
      message={t("DeleteConfirmation")}
      />
    </>
    
  )
}

export default TruckTypes