import { Box, Typography, Skeleton } from "@mui/material";

const DashboardSkeleton = () => {
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Skeleton variant="text" width={200} height={40} animation="wave" />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        {[...Array(4)].map((_, index) => (
          <Box
            key={index}
            gridColumn="span 3"
            backgroundColor="#f0f0f0"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Skeleton
              variant="rectangular"
              width="80%"
              height="70%"
              animation="wave"
            />
          </Box>
        ))}

        {/* ROW 2 - Invoices */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor="#f0f0f0"
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h5">
                <Skeleton variant="text" width={150} animation="wave" />
              </Typography>
              <Typography variant="h3">
                <Skeleton variant="text" width={250} animation="wave" />
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              animation="wave"
            />
          </Box>
        </Box>

        {/* ROW 2 - Recent Orders */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor="#f0f0f0"
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid #ccc"
            p="15px"
          >
            <Typography variant="h5">
              <Skeleton variant="text" width={150} animation="wave" />
            </Typography>
          </Box>
          {[...Array(5)].map((_, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #ccc"
              p="15px"
            >
              <Box>
                <Typography variant="h5">
                  <Skeleton variant="text" width={150} animation="wave" />
                </Typography>
                <Typography>
                  <Skeleton variant="text" width={100} animation="wave" />
                </Typography>
              </Box>
              <Box>
                <Skeleton variant="text" width={80} animation="wave" />
              </Box>
              <Box>
                <Skeleton variant="text" width={50} animation="wave" />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardSkeleton;
