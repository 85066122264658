import * as Actions from "../actions";

const initState = {
  pendingDrivers: [],
  acceptedDrivers: [],
  pendingProviders: [],
  acceptedProviders: [],
};

const usersReducer = (state = initState, action) => {
  switch (action.type) {
    case Actions.GET_PENDING_DRIVERS: {
      return {
        ...state,
        pendingDrivers: action.payload,
      };
    }
    case Actions.GET_ACCEPTED_DRIVERS: {
      return {
        ...state,
        acceptedDrivers: action.payload,
      };
    }
    case Actions.GET_PENDING_PROVIDERS: {
      return {
        ...state,
        pendingProviders: action.payload,
      };
    }
    case Actions.GET_ACCEPTED_PROVIDERS: {
      return {
        ...state,
        acceptedProviders: action.payload,
      };
    }

    default:
      return state;
  }
};

export default usersReducer;
