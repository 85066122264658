import axios from "axios";

export const GET_TRUCKS = "[TRUCKS APP] GET_TRUCKS";
export const CREATE_TRUCKS = "[TRUCKS APP] CREATE_TRUCKS";
export const UPDATE_TRUCKS = "[TRUCKS APP] UPDATE_TRUCKS";
export const DELETE_TRUCKS = "[TRUCKS APP] DELETE_TRUCKS";

const api = process.env.REACT_APP_API_URL;

export function getAllTrucks() {
  const url = `${api}/truck-types`;
  return (dispatch) => {
    const request = axios.get(url);
    return request.then((response) =>
      dispatch({
        type: GET_TRUCKS,
        payload: response.data,
      })
    );
  };
}
export function createTrucks(data) {
  const url = `${api}/truck-types`;
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("image_url", data.image_url, data.image_url.name);
  return (dispatch) => {
    const request = axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return request.then((response) =>
      dispatch({
        type: CREATE_TRUCKS,
        payload: response.data,
      })
    );
  };
}
export function editTrucks(data) {
  const url = `${api}/truck-types/${data.id}`;
  const formData = new FormData();

  formData.append("name", data.name);
  if (data.image_url instanceof File) {
    formData.append("image_url", data.image_url, data.image_url.name);
  }
  return (dispatch) => {
    const request = axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return request.then((response) =>
      dispatch({
        type: UPDATE_TRUCKS,
        payload: response.data,
      })
    );
  };
}

export function deleteTruck(data) {
  const url = `${api}/truck-types/${data.id}`;
  return (dispatch) => {
    const request = axios.delete(url);
    return request.then((response) =>
      dispatch({
        type: DELETE_TRUCKS,
        payload: response.data,
      })
    );
  };
}
