import * as Actions from "../actions";

const initState = {
  data: [],
  transactions: [],
};

const accountsReducer = (state = initState, action) => {
  switch (action.type) {
    case Actions.GET_REQUIRED_MONEY: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case Actions.GET_DRIVER_TRANSACTIONS: {
      return {
        ...state,
        transactions: action.payload,
      };
    }

    default:
      return state;
  }
};

export default accountsReducer;
