import axios from "axios";
import { toast } from "react-hot-toast";

axios.interceptors.request.use(
  (config) => {
    // Modify the request config to include headers
    config.headers["Accept"] = "application/json";

    // Check if the request contains a FormData object
    if (!(config.data instanceof FormData)) {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    const requestMethod = response.config.method.toLowerCase();
    switch (requestMethod) {
      case "post":
        toast.success("Done Successfully");
        break;
      case "put":
        toast.success("Updated Successfully");
        break;
      case "delete":
        toast.success("Deleted Successfully");
        break;
      default:
        break;
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        toast.error("please login again");
        document.cookie.split(";").forEach(function (cookie) {
          document.cookie = cookie
            .replace(/^ +/, "")
            .replace(
              /=.*/,
              "=;expires=" + new Date().toUTCString() + ";path=/"
            );
        });
        window.location.reload();
      }
      if (error.response.status === 422) {
        toast.error("please fill all required data");
      }
      if (error.response.status === 400) {
        toast.error(error.response.data.message);
      }
      if (error.response.status === 404) {
        toast.error("data requested not found");
      }
      console.log(error.response.data);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
    return Promise.reject(error);
  }
);
