import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import { useSignIn } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { t } from 'i18next';

const Login = () => {
  const signIn = useSignIn()
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup
        .string()
        .max(255)
        .required('Password is required')
    }),
    onSubmit: async (values, helpers) => {
      try {
        const data  = {
          'email':values.email,
          'password':values.password
        };
        // dispatch(authAction.login(data))
      const api = process.env.REACT_APP_API_URL;

        const url = `${api}/dashboard/login`;
        const request = axios.post(url, data);
        request.then((res)=>{
          if(signIn(
            {
                token: res.data.token,
                expiresIn:30000,
                tokenType: "Bearer",
                authState: res.data.user,
            }
        )){
          window.location.reload();

        }else{
          throw new Error("Something wrong happend");
        }
        });
   

        
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });




  return (
    <>

      <Box
        sx={{
        //   backgroundColor: 'background.paper',
          flex: '1 1 auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: '100px',
            width: '100%'
          }}
        >
          <div>
            <Stack
              spacing={1}
              sx={{ mb: 3 }}
            >
          <img src={'../logo.png'} alt="Logo" style={{ maxWidth: '100px', marginBottom: '20px' , margin:'auto'}} />

              <Typography variant="h4">
                {t("Login")}
              </Typography>
              <Typography
                color="text.secondary"
                variant="body2"
              >
      
              </Typography>
            </Stack>

            <form
                noValidate
                onSubmit={formik.handleSubmit}
              >
                <Stack spacing={3}>
                  <TextField
                    error={!!(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    label={t("Email")}
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    value={formik.values.email}
                  />
                  <TextField
                    error={!!(formik.touched.password && formik.errors.password)}
                    fullWidth
                    helperText={formik.touched.password && formik.errors.password}
                    label={t("Password")}
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="password"
                    value={formik.values.password}
                  />
                </Stack>
        
                {formik.errors.submit && (
                  <Typography
                    color="error"
                    sx={{ mt: 3 }}
                    variant="body2"
                  >
                    {formik.errors.submit}
                  </Typography>
                )}
                <Button
                  fullWidth
                  
                  size="large"
                  sx={{ mt: 3 }}
                  type="submit"
                  variant="contained"
                >
                  {t("Continue")}
                </Button>


              </form>
          </div>
        </Box>
      </Box>
    </>
  );
};

Login.getLayout = (Login) => (
  <div>
    {Login}
  </div>
);

export default Login;
