import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { AddBusiness, BusinessCenter, ChatBubble, Domain, FireTruck, JoinInner, LocalShipping, Settings, ShoppingBasketRounded, Wallet } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const sidebarStyle = {
    paddingLeft: isCollapsed ? undefined : theme.direction === "rtl" ? "0" : "10%",
    paddingRight: isCollapsed ? undefined : theme.direction === "rtl" ? "10%" : "0",
  };

  const mergedStyles = {
    "& .pro-sidebar-inner": {
      background: `${colors.primary[400]} !important`,
    },
    "& .pro-icon-wrapper": {
      backgroundColor: "transparent !important",
    },
    "& .pro-inner-item": {
      padding: "5px 35px 5px 20px !important",
    },
    "& .pro-inner-item:hover": {
      color: "#868dfb !important",
    },
    "& .pro-menu-item.active": {
      color: "#6870fa !important",
    },
  };
  return (
    <Box sx={mergedStyles}>
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <img
                  alt="profile-user"
                  width="70px"
                  height="50px"
                  src={`../../logo.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
                <Typography variant="h3" color={colors.grey[100]}>
                  {t("Trans")}
                </Typography>
                
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>


          <Box paddingRight={isCollapsed ? undefined : "10%"} paddingLeft={isCollapsed ? undefined : "10%"} textAlign={"start"}>

            <Item
              title= {t("Dashboard")}
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />


              <Typography
              variant="h5"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 0" ,fontWeight:"bold",textAlign:"start"}}
            >
              {t("UsersManagement")}
            </Typography>
       
            <Item
              title= {t("DriversRequests")}
              to="/drivers-request"
              icon={<ContactMailIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title= {t("ProvidersRequests")}
              to="/providers-request"
              icon={<AddBusiness />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
             title= {t("ActiveDrivers")}
              to="/active-drivers"
              icon={<LocalShipping/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title= {t("ActiveProviders")}
              to="/active-providers"
              icon={<Domain/>}
              selected={selected}
              setSelected={setSelected}
            />
               <Typography
              variant="h5"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 0" ,fontWeight:"bold",textAlign:"start"}}
            >
              {t("Data")}
            </Typography>
            <Item
             title= {t("Accounts")}
              to="/drivers-accounts"
              icon={<Wallet />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
             title= {t("Orders")}
              to="/orders"
              icon={<ShoppingBasketRounded />}
              selected={selected}
              setSelected={setSelected}
            />

            
            <Typography
              variant="h5"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 0" ,fontWeight:"bold",textAlign:"start"}}
            >
              {t("System")}
            </Typography>

            <Item
              title= {t("TruckTypes")}
              to="/truckTypes"
              icon={<FireTruck />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title= {t("SystemParameters")}
              to="/system-parameters"
              icon={<Settings />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title= {t("SupportChats")}
              to="/chats"
              icon={<ChatBubble />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
