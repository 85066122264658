import axios from "axios";

export const TEST = "[AUDIT APP] GET AUDITS";

const api = process.env.REACT_APP_API_URL;

export function openFilterDialogue() {
  return {
    type: TEST,
  };
}

// export function testFunction() {
//   const url = `${api}/test`;
//   return (dispatch) => {
//     const request = axios.get(url);
//     return request.then((response) =>
//       dispatch({
//         type: TEST,
//         payload: response.data,
//       })
//     );
//   };
// }
