const locale = {
  Trans: "Trans",
  Dashboard: "Dashboard",
  DriversRequests: "Drivers Requests",
  ProvidersRequests: "Providers Requests",
  ActiveDrivers: "Active Drivers",
  ActiveProviders: "Active Providers",
  Accounts: "Accounts",
  Orders: "Orders",
  TruckTypes: "Truck Types",
  SystemParameters: "System Parameters",
  SupportChats: "Support chats",
  UsersManagement: "Users Management",
  Data: "Data",
  System: "System",

  Drivers: "Drivers",
  Providers: "Providers",
  Clients: "Clients",
  Invoices: "Invoices",
  RecentOrders: "Recent Orders",

  Name: "Name",
  Email: "Email",
  Phone: "Phone",
  Address: "Address",
  IdImage: "ID Image",
  TruckImage: "Truck Image",
  HasTruck: "Has truck",
  ProfileImageUrl: "Profile Image",
  CompanyImage: "Company Image",
  TruckName: "Truck Name",
  LoadByTon: "Load by Ton",
  PlateNumber: "Plate Number",
  Symbol: "Symbol",
  Governorate: "Governorate",
  companyName: "Company Name",
  companyPhone: "Company Phone",
  Debt: "Debt",
  Payoff: "Payoff",
  Closed: "Closed",
  NewPassword: "New Password",
  OldPassword: "Old Password",
  OldPasswordIsRequired: "Old Password Is Required",
  NewPasswordIsRequired: "New Password Is Required",
  MustBeValidEmail: "Must Be Valid Email",
  EmailIsRequired: "Email Is Required",
  DeleteOrder: "Delete order",

  ConfirmationAcceptRequest: "Are you sure you want to accept this request?",
  ConfirmationDeleteRequest: "Are you sure you want to delete this request?",
  DeactivateRequest: "Are you sure you want to deactivate this user?",
  DeleteConfirmation: "Are you sure you want to delete this?",
  Edit: "Edit",
  Delete: "Delete",
  Create: "Create",
  NoDataAvailable: "No Data Available",
  Confirmation: "Confirmation",
  Cancel: "Cancel",
  Confirm: "Confirm",
  Close: "Close",
  Deactivate: "Deactivate",
  Transactions: "Transactions",
  AddPayoff: "AddPayoff",
  AcountLocked: "Locked status",
  RequiredAmount: "Required Amount",

  OrderNumber: "Order number",
  ProviderName: "Provider name",
  ClientName: "Client name",
  ClientPhone: "Client phone",
  TotalCost: "Total cost",
  Date: "Date",
  Description: "Description",
  Value: "Value",

  TypeAMessage: "Type a message",
  Chats: "Chats",
  ChatMessages: "Chat messages",
  RecordingInProgress: "Recording in progress",

  CreatedAt: "Created At",
  ProductsPrice: "Products Price",
  TransportCost: "Transport Cost",
  Provider: "Provider",

  Client: "Client",
  Company: "Company",
  Products: "Products",
  Price: "Price",
  Quantity: "Quantity",
  Trucks: "Trucks",
  Image: "Image",
  Cost: "Cost",
  RequestCountsByStatus: "Request Counts By Status",
  Count: "Count",
  DriverNames: "Driver Names",
  Continue: "Continue",
  Login: "Login",
  Password: "Password",
};
export default locale;
