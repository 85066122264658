import axios from "axios";

export const GET_PENDING_DRIVERS = "[USERS APP] GET_PENDING_DRIVERS";
export const GET_ACCEPTED_DRIVERS = "[USERS APP] GET_ACCEPTED_DRIVERS";
export const GET_PENDING_PROVIDERS = "[USERS APP] GET_PENDING_PROVIDERS";
export const GET_ACCEPTED_PROVIDERS = "[USERS APP] GET_ACCEPTED_PROVIDERS";
export const DEACTIVATE_USER = "[USERS APP] DEACTIVATE_DRIVER";
export const ACTIVATE_USER = "[USERS APP] ACCEPT_PENDING_REQUEST";
export const DELETE_USER = "[USERS APP] DELETE_USER";

const api = process.env.REACT_APP_API_URL;

export function getDriverjoiningRequests() {
  const url = `${api}/dashboard/users/getDriverjoiningRequests`;
  return (dispatch) => {
    const request = axios.get(url);
    return request.then((response) =>
      dispatch({
        type: GET_PENDING_DRIVERS,
        payload: response.data,
      })
    );
  };
}
export function getAcceptedDrivers() {
  const url = `${api}/dashboard/users/getAcceptedDrivers`;
  return (dispatch) => {
    const request = axios.get(url);
    return request.then((response) =>
      dispatch({
        type: GET_ACCEPTED_DRIVERS,
        payload: response.data,
      })
    );
  };
}

export function getProviderjoiningRequests() {
  const url = `${api}/dashboard/users/getProviderjoiningRequests`;
  return (dispatch) => {
    const request = axios.get(url);
    return request.then((response) =>
      dispatch({
        type: GET_PENDING_PROVIDERS,
        payload: response.data,
      })
    );
  };
}
export function getAcceptedProviders() {
  const url = `${api}/dashboard/users/getAcceptedProviders`;
  return (dispatch) => {
    const request = axios.get(url);
    return request.then((response) =>
      dispatch({
        type: GET_ACCEPTED_PROVIDERS,
        payload: response.data,
      })
    );
  };
}

export function activateUser(data) {
  const url = `${api}/dashboard/users/activateUser`;
  return (dispatch) => {
    const request = axios.post(url, { id: data.id });
    return request.then((response) =>
      dispatch({
        type: ACTIVATE_USER,
        payload: response.data,
      })
    );
  };
}
export function deactivateUser(data) {
  const url = `${api}/dashboard/users/deactivateUser`;
  return (dispatch) => {
    const request = axios.post(url, { id: data.id });
    return request.then((response) =>
      dispatch({
        type: DEACTIVATE_USER,
        payload: response.data,
      })
    );
  };
}
export function deleteUser(data) {
  const url = `${api}/dashboard/users/deleteUser`;
  return (dispatch) => {
    const request = axios.post(url, { id: data.id });
    return request.then((response) =>
      dispatch({
        type: DELETE_USER,
        payload: response.data,
      })
    );
  };
}
