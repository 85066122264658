import { useTheme } from '@emotion/react';
import { FileUploadOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import { t } from 'i18next';
import React, { useState, useEffect } from 'react';

function AwesomeDialog({ data, title, open, columns, onClose, onSubmit }) {
  const [values, setValues] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const  images = process.env.REACT_APP_IMAGES ;

  useEffect(() => {
    if (data) {
      setValues(data);
    } else {
      setValues({});
    }
  }, [data]);

  const handleSubmit = () => {
    const errors = validateFields();
    if (Object.keys(errors).length === 0) {
      onSubmit(values);
      onClose();
    } else {
      setValidationErrors(errors);
    }
  };

  const validateFields = () => {
    const errors = {};
    columns.forEach((column) => {
      if (column.required && !values[column.accessorKey]) {
        errors[column.accessorKey] = 'This field is required';
      }
    });
    return errors;
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setValidationErrors({ ...validationErrors, [e.target.name]: '' });
  };
  const renderImagePreview = () => {
    if (values?.image_url instanceof File) {
      return (
        <img
          alt="avatar"
          width={100}
          height={100}
          src={URL.createObjectURL(values?.image_url)}
          loading="lazy"
          style={{ borderRadius: '2%' }}
        />
      );
    } else if (typeof values?.image_url === 'string') {
      return (
        <img
          alt="avatar"
          width={100}
          height={100}
          src={`${images}/${values?.image_url}`}
          loading="lazy"
          style={{ borderRadius: '2%' }}
        />
      );
    }
    return null;
  };
  const theme = useTheme();
  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">{title}</DialogTitle>
      <DialogContent >
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => (
              !column.mapped? <></>: 
              column.type === 'text' ? (
                <TextField
                dir={theme.direction}
                sx={{margin:"20px"}}
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  value={values[column.accessorKey] || ''}
                  required={column.required}
                  error={!!validationErrors[column.accessorKey]}
                  helperText={validationErrors[column.accessorKey]}
                  onChange={handleChange}
                  
                />
              ) : (
                <>
                  <TextField
                    variant="standard"
                    type="text"
                    dir={theme.direction}
                    value={values?.image_url?.name || ''}
                    InputProps={{
                      endAdornment: (
                        <IconButton component="label">
                          <FileUploadOutlined />
                          <input
                            style={{ display: 'none' }}
                            type="file"
                            hidden
                            onChange={(e) => {
                              const file = e.target.files[0];
                              setValues({ ...values, [e.target.name]: file });
                            }}
                            name="image_url"
                          />
                        </IconButton>
                      ),
                    }}
                    required
                    error={!!validationErrors.image_url}
                    helperText={validationErrors.image_url}
                  />
                {renderImagePreview()}
                </>
              )
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <Button color={data ? 'secondary' : 'info'} onClick={handleSubmit} variant="contained">
          {data ? t('Edit') : t('Create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AwesomeDialog;
