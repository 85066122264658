import { combineReducers } from "@reduxjs/toolkit";
import counterReducer from "./counter/reducer";
import trucksReducer from "./trucks/reducer";
import accountsReducer from "./accounts/reducer";
import systemParametersReducer from "./systemParameters/reducer";
import ordersReducer from "./orders/reducer";
import homeReducer from "./home/reducer";
import usersReducer from "./users/reducer";

const rootReducer = combineReducers({
  counter: counterReducer,
  trucks: trucksReducer,
  users: usersReducer,
  accounts: accountsReducer,
  systemParameters: systemParametersReducer,
  orders: ordersReducer,
  home: homeReducer,
});

export default rootReducer;
