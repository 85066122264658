import * as Actions from "../actions";

const initState = {
  data: {},
};

const trucksReducer = (state = initState, action) => {
  switch (action.type) {
    case Actions.GET_TRUCKS: {
      return {
        ...state,
        data: action.payload,
      };
    }

    default:
      return state;
  }
};

export default trucksReducer;
