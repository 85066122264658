import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCq5SElJVos70cFqv6cb0jJIbVEGkuIgTM",
  authDomain: "trans-merchant.firebaseapp.com",
  projectId: "trans-merchant",
  storageBucket: "trans-merchant.appspot.com",
  messagingSenderId: "998576818108",
  appId: "1:998576818108:web:fcfa26f8cf20ecc5cacdb9",
  measurementId: "G-04Y0YY66PW",
};

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app, { vapidKey: publicKey });

export const fetchToken = async (setTokenFound) => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
      return currentToken;
    } else {
      setTokenFound(false);
      console.log(
        "No registration token available. Request permission to generate one."
      );
      return null;
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
    return null;
  }
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
