import axios from "axios";

export const GET_PARAMETERS = "[SYSTEM APP] GET_PARAMETERS";
export const UPDATE_PARAMETERS = "[SYSTEM APP] UPDATE_PARAMETERS";

const api = process.env.REACT_APP_API_URL;

export function getSystemParameters() {
  const url = `${api}/dashboard/system`;
  return (dispatch) => {
    const request = axios.get(url);
    return request.then((response) =>
      dispatch({
        type: GET_PARAMETERS,
        payload: response.data,
      })
    );
  };
}
export function updateSystemParameters(data) {
  const url = `${api}/dashboard/system/updateSystemParameters`;
  return (dispatch) => {
    const request = axios.put(url, data);
    return request.then((response) =>
      dispatch({
        type: GET_PARAMETERS,
        payload: response.data,
      })
    );
  };
}
