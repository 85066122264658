import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import Transactions from "./Transactions";
import { t } from "i18next";

function TradingDialog({ data, title, open, onClose }) {
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle textAlign="center">{title}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: "100%",
            height: "60%",
            gap: "1.5rem",
            overflow: "auto",
          }}
        >
          <Transactions data={data} />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button color="inherit" onClick={onClose}>{t("Close")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default TradingDialog;
