import {  CloseFullscreen, CloudDownload } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, useTheme, Typography } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { useState } from 'react';

function AwesomeDataDialog({ data, title, open, columns, onClose }) {

  const  images = process.env.REACT_APP_IMAGES ;



  const [showModal, setShowModal] = useState(false);

  const handleImageClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  const renderImagePreview = (value) => {

  const handleDownloadImage = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = !data[value.accessorKey] ? '../../noimage.jpg' : `${images}/${data[value.accessorKey]}`;
    downloadLink.download = 'image.jpg'; 
    downloadLink.click();
  };
    return (
    <div style={{ position: 'relative' }}>
      <img
        alt="avatar"
        width={100}
        height={100}
        src={!data[value.accessorKey] ? '../../noimage.jpg' : `${images}/${data[value.accessorKey]}`}
        loading="lazy"
        style={{ borderRadius: '2%', cursor: 'pointer' }}
        onClick={handleImageClick}
      />
      {showModal && (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 999 }}>
          <div style={{ background: '#fff', padding: '20px', borderRadius: '4px', maxWidth: '90vw', maxHeight: '90vh', position: 'relative' }}>
            <img
              alt="avatar"
              src={!data[value.accessorKey] ? '../../noimage.jpg' : `${images}/${data[value.accessorKey]}`}
              loading="lazy"
              style={{ width: '100%', height: 'auto', cursor: 'zoom-out' }}
              onClick={handleCloseModal}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
            >
              <div
                style={{
                  cursor: 'pointer',
                  background: '#ffffff',
                  borderRadius: '50%',
                  padding: '5px',
                  width:'30px',
                  height:'30px',
                  marginRight: '10px',
                }}
              >
                <CloudDownload fontSize="medium" onClick={handleDownloadImage} style={{ color: '#444' }} />
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  background: '#ffffff',
                  borderRadius: '50%',
                  padding: '5px',
                  width:'30px',
                  height:'30px',
                }}
              >
                <CloseFullscreen fontSize="medium" onClick={handleCloseModal} style={{ color: '#444' }} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  };



  const theme = useTheme();

  return (
    <Dialog open={open} dir={theme.direction}>
      <DialogTitle textAlign="center">{title}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '400px', sm: '460px', md: '500px' },
            gap: '1.5rem',
          }}
        >
          {columns.map((column) => (
            column.type === 'text' ? (
              <>
            <Typography variant="h5" sx={{ marginBottom: theme.spacing(2), width:"30%", fontWeight: 'bold', }}>
            {column.header|| ''}: 
          </Typography>
              <Typography sx={{   borderBottom: `1px solid ${theme.palette.primary.main}`}}>
                 {data[column.accessorKey] || ''} </Typography>
              </>
            ) : (
              <>
                <Typography variant="h5" sx={{ marginBottom: theme.spacing(2), width:"30%", fontWeight: 'bold', }}>
                  {column.header|| ''} 
                </Typography>
                 {renderImagePreview(column)}
              </>
            )
          ))}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button color='inherit' onClick={onClose} >
          {t("Close")}
        </Button>

      </DialogActions>
    </Dialog>
  );
}

export default AwesomeDataDialog;
