import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import AwesomeTable from '../../components/Awesome/AweomeTable';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';
import { BlockRounded, RemoveRedEye } from '@mui/icons-material';
import { deactivateUser, getAcceptedProviders } from '../../data/users/actions/users.actions';
import { t } from 'i18next';
import AwesomeDataDialog from '../../components/Awesome/AwesomDataDialog';
import Loader from '../../components/Common/Loader';

function AcceptedProviders() {
  const dispatch = useDispatch();
  const data  = useSelector((state)=> state.users);
  const [selectedRow, setSelectedRow] = useState();
  const [openView, setOpenView] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [acceptedValue, setAcceptedValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);




  useEffect(()=>{
    setIsLoading(true);

    dispatch(getAcceptedProviders()).then((res)=>{
      setIsLoading(false);

    });
  },[dispatch]);




  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  const handleAcceptConfirmation = (value) => {
    setAcceptedValue(value);
    setConfirmationOpen(true);
  };

  const setAcceptOptions=()=>{
    dispatch(deactivateUser(acceptedValue))
    .then(() => {
      dispatch(getAcceptedProviders());
      setConfirmationOpen(false);
    })
    .catch((error) => {
      setConfirmationOpen(false);
    });
  }


  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Name'),
        type: 'text',
        required: false,
        size: 140,
      },
      {
        accessorKey: 'email',
        header: t('Email'),
        type: 'text',
        required: false,
        size: 140,
      },
      {
        accessorKey: 'phone',
        header: t('Phone'),
        type: 'text',
        required: false,
        size: 140,
      },
      {
        accessorKey: 'address',
        header: t('Address'),
        type: 'text',
        required: false,
        size: 140,
      },


    ],
    [data],
  );

  console.log()

  const RowActions =(row)=>{
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>

          <Tooltip arrow placement="left" title={t("Deactivate")}>
            <IconButton color="error" variant="contained" onClick={() => {
              
              handleAcceptConfirmation(row.original);
              setConfirmationOpen(true);
              
              }}>
                <BlockRounded/>
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="left" title={t("View")}>
            <IconButton color="error" variant="contained" onClick={() => {
              
              setSelectedRow(row.original);
              setOpenView(true);
              
              }}>
                <RemoveRedEye color='success'/>
            </IconButton>
          </Tooltip>
         
       
        </Box>
      </>
    );
  }
  if(isLoading){
    return <Loader/>
  }

  return (
    <>
    <AwesomeTable 
    columns={columns}
    data={data?.acceptedProviders?.data}
    showActions
    RowActions={RowActions}
    showTopActions ={false}

    />

{selectedRow && <AwesomeDataDialog
      title={selectedRow['name']}
      data = {selectedRow}
      columns={[  
        { accessorKey: 'name', type: 'text', header: t('Name') },
        { accessorKey: 'email', type: 'text', header: t('Email') },
        { accessorKey: 'phone', type: 'text', header: t('Phone') },
        { accessorKey: 'address', type: 'text', header: t('Address') },
        { accessorKey: 'company_image', header: t('CompanyImage') },
       
        { accessorKey: 'company_name',type: 'text', header: t('companyName') },
        { accessorKey: 'company_phone',type: 'text', header: t('companyPhone') },

      ]}
      open={openView}
      onClose={() => {
        setOpenView(false)
        setSelectedRow()
      }}
    />}
    <ConfirmationDialog
      open={confirmationOpen}
      onClose={handleCloseConfirmation}
      onConfirm={setAcceptOptions}
      message={t("DeactivateRequest")}
      />
      
    </>
  )
}

export default AcceptedProviders