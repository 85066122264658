import axios from "axios";

export const GET_REQUIRED_MONEY = "[ACCOUNTS APP] GET_REQUIRED_MONEY";
export const GET_DRIVER_TRANSACTIONS = "[ACCOUNTS APP] GET_DRIVER_TRANSACTIONS";
export const CREATE_PAYMENT_MONEY = "[ACCOUNTS APP] CREATE_PAYMENT_MONEY";

const api = process.env.REACT_APP_API_URL;

export function requiredMoneyFromAllDrivers() {
  const url = `${api}/dashboard/accounting/requiredMoneyFromAllDrivers`;
  return (dispatch) => {
    const request = axios.get(url);
    return request.then((response) =>
      dispatch({
        type: GET_REQUIRED_MONEY,
        payload: response.data,
      })
    );
  };
}
export function getDriverTransactions(data) {
  const url = `${api}/dashboard/accounting/transactions?driver_id=${data.id}`;
  return (dispatch) => {
    const request = axios.get(url);
    return request.then((response) =>
      dispatch({
        type: GET_DRIVER_TRANSACTIONS,
        payload: response.data,
      })
    );
  };
}
export function createPayoffPayment(data) {
  const url = `${api}/dashboard/accounting/createPayoffPayment`;
  return (dispatch) => {
    const request = axios.post(url, data);
    return request.then((response) =>
      dispatch({
        type: CREATE_PAYMENT_MONEY,
        payload: response.data,
      })
    );
  };
}
