import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { t } from 'i18next';
import React from 'react'
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { updateAdminProfile } from '../../data/home/actions';

function EditProfile({admin, handleCancelEdit,handleSaveChanges}) {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
          email: admin.email,
          oldPassword: '',
          newPassword: '',
          submit: null
        },
        validationSchema: Yup.object({
          email: Yup
            .string()
            .email(t('MustBeValidEmail'))
            .max(255)
            .required(t('EmailIsRequired')),
          oldPassword: Yup
            .string()
            .max(255)
            .required(t('OldPasswordIsRequired')),
          newPassword: Yup
            .string()
            .max(255)
            .required(t('NewPasswordIsRequired'))
        }),
        onSubmit: async (values, helpers) => {
          try {
            const data  = {
              'email':values.email,
              'old_password':values.oldPassword,
              'new_password':values.newPassword
            };
            dispatch(updateAdminProfile(data)).then((res)=>{
                handleSaveChanges();
            })
    

       
    
            
          } catch (err) {
            console.error(err);
            helpers.setStatus({ success: false });
            helpers.setErrors({ submit: err.message });
            helpers.setSubmitting(false);
          }
        }
      });
    
  return (
    <Box sx={{width:'100%'}}>
           <form
                noValidate
                onSubmit={formik.handleSubmit}
              >
                <Stack spacing={3}>
                  <TextField
                    error={!!(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    label={t("Email")}
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.email}
                  />
                  <TextField
                    error={!!(formik.touched.oldPassword && formik.errors.oldPassword)}
                    fullWidth
                    helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                    label={t("OldPassword")}
                    name="oldPassword"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    value={formik.values.oldPassword}
                  />
                  <TextField
                    error={!!(formik.touched.newPassword && formik.errors.newPassword)}
                    fullWidth
                    helperText={formik.touched.newPassword && formik.errors.newPassword}
                    label={t("NewPassword")}
                    name="newPassword"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.newPassword}
                  />
                </Stack>
        
                {formik.errors.submit && (
                  <Typography
                    color="error"
                    sx={{ mt: 3 }}
                    variant="body2"
                  >
                    {formik.errors.submit}
                  </Typography>
                )}
                <Box sx={{
                    display:"flex",
                    flexDirection:"row",
                    gap:'1rem'
                }}>

                <Button
                  fullWidth
                  
                  size="large"
                  sx={{ mt: 3 }}
                  type="submit"
                  variant="contained"
                >
                  {t("Confirm")}
                </Button>
                <Button
                  fullWidth
                  
                  size="large"
                  sx={{ mt: 3 }}
                  variant="outlined"
                  onClick={handleCancelEdit}
                >
                  {t("Cancel")}
                  
                </Button>
                  </Box>


              </form>
    </Box>
  )
}

export default EditProfile