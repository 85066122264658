import axios from "axios";

export const GET_ORDERS = "[ORDER APP] GET_ORDERS";
export const GET_ORDER_DETAILS = "[ORDER APP] GET_ORDER_DETAILS";
export const DELETE_ORDER = "[ORDER APP] DELETE_ORDER";

const api = process.env.REACT_APP_API_URL;

export function getOrdersOverView() {
  const url = `${api}/dashboard/order`;
  return (dispatch) => {
    const request = axios.get(url);
    return request.then((response) =>
      dispatch({
        type: GET_ORDERS,
        payload: response.data,
      })
    );
  };
}
export function getOrderDetails(data) {
  if (data != null && data !== undefined) {
    const url = `${api}/dashboard/order/${data.id}`;
    return (dispatch) => {
      const request = axios.get(url);
      return request.then((response) =>
        dispatch({
          type: GET_ORDER_DETAILS,
          payload: response.data,
        })
      );
    };
  }
}
export function deleteOrder(data) {
  if (data != null && data !== undefined) {
    const url = `${api}/dashboard/order/${data.id}`;
    return (dispatch) => {
      const request = axios.delete(url);
      return request.then((response) =>
        dispatch({
          type: DELETE_ORDER,
          payload: response.data,
        })
      );
    };
  }
}
