import React, { useEffect, useMemo, useState } from 'react'
import AwesomeTable from '../../components/Awesome/AweomeTable'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { deleteOrder, getOrderDetails, getOrdersOverView } from '../../data/orders/actions';
import OrderDialog from './orderDialog';
import { t } from 'i18next';
import Loader from '../../components/Common/Loader';
import { Delete, RemoveRedEye } from '@mui/icons-material';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';

function OrdersOverView() {
  const dispatch = useDispatch();
  const data  = useSelector((state)=> state.orders);
  const order  = useSelector((state)=> state.orders.order);
  const [selectedRow, setSelectedRow] = useState();
  const [openView, setOpenView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [deletedValue, setDeletedValue] = useState(null);

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };
  const setDeleteOptions=()=>{
    dispatch(deleteOrder(deletedValue))
    .then(() => {
      dispatch(getOrdersOverView());
      setConfirmationOpen(false);
    })
    .catch((error) => {
      setConfirmationOpen(false);
    });
  }

  useEffect(()=>{
    setIsLoading(true);
    dispatch(getOrdersOverView()).then((res)=>{
      setIsLoading(false);

    });
  },[dispatch]);

  useEffect(()=>{
    if (selectedRow != null && selectedRow !== undefined) {
    dispatch(getOrderDetails(selectedRow)).then((res)=>{
      setOpenView(true)
    });
  }
  },[selectedRow,dispatch]);
 


  const columns = useMemo(
    () => [
      {
        accessorKey: 'order_number',
        header: t("OrderNumber"),
        type: 'text',
        required:true,
        size: 75
      },
      {
        accessorKey: 'provider_name',
        header: t("ProviderName"),
        type: 'text',
        required:true,
        size: 75
      },

      {
        accessorKey: 'client_name',
        header: t("ClientName"),
        type: 'text',
        required:true,
        size: 75
      },
      {
        accessorKey: 'client_phone',
        header: t("ClientPhone"),
        type: 'text',
        required:true,
        size: 75
      },

      {
        accessorKey: 'total_cost',
        header: t("TotalCost"),
        type: 'text',
        required:true,
        size: 75
      },
      {
        accessorKey: 'created_at',
        header: t("Date"),
        type: 'text',
        required:true,
        size: 75
      },
      
    ],
    [data.data],
  );
  const RowActions =(row)=>{
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
          <Tooltip arrow placement="left" title={t("View")}>
            <IconButton  variant="contained" onClick={() => {
              
              setSelectedRow(row.original); }}>
                <RemoveRedEye color='warning'/>
            </IconButton>
            
   
          </Tooltip>
          <Tooltip arrow placement="left" title={t("Delete")}>
          <IconButton  variant="contained" onClick={() => {
              
              setDeletedValue(row.original);
              setConfirmationOpen(true)
              }}>
                
                <Delete color='error'/>
            </IconButton>
          </Tooltip>
  
        </Box>
      </>
    );
  }
 
  if(isLoading){
    return <Loader/>
  }
  return (
    <>
    <AwesomeTable 
    columns={columns}
    data={data?.data?.orders}
    RowActions={RowActions}
    showActions
    showTopActions={false}

    />
    {order && <OrderDialog
      title={order['order_number']}
      order = {order}
      open={openView}
      onClose={() => {
        setOpenView(false)
        setSelectedRow()
      }}
    />}

      <ConfirmationDialog
      open={confirmationOpen}
      onClose={handleCloseConfirmation}
      onConfirm={setDeleteOptions}
      message={t("DeleteConfirmation")}
      />
      
    </>
    
  )
}

export default OrdersOverView