import React, { useEffect, useMemo, useState } from 'react'
import AwesomeTable from '../../components/Awesome/AweomeTable'
import { useDispatch, useSelector } from 'react-redux';
import AwesomeDialog from '../../components/Awesome/AwesomeDialog';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import {  Edit } from '@mui/icons-material';
import { getSystemParameters, updateSystemParameters } from '../../data/systemParameters/actions';
import { t } from 'i18next';
import Loader from '../../components/Common/Loader';

function SystemParameters() {
  const dispatch = useDispatch();
  const data  = useSelector((state)=> state.systemParameters);

  
  const [editedRow, setEditedRow] = useState();
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const [transformedData, setTransformedData] = useState([]); // State to store the transformed data

  useEffect(() => {
    setIsLoading(true);

    dispatch(getSystemParameters()).then((res)=>{
      setIsLoading(false);

    });
  }, [dispatch]);

  useEffect(() => {
    // Transform data when it becomes available and is not an empty object
    if (data?.data && Object.keys(data.data).length > 0) {
      setTransformedData(
        data.data.map((item) => ({
          description: item.description,
          value: item.value,
          key: item.key
        }))
      );
    }
  }, [data]);


 const handleEdit =(values)=>{
  const existingData = data?.data || [];

  // Find the index of the edited parameter in the existing data
  const editedIndex = existingData.findIndex((item) => item.key === values.key);

  // If the parameter is found in the existing data, update its value
  if (editedIndex !== -1) {
    const newData = [...existingData];
    newData[editedIndex] = { ...newData[editedIndex], value: values.value };

    // Map newData to match the backend schema
    const transformedValues = {
      company_share: parseFloat(newData.find((item) => item.key === 'company_share')?.value) || 0,
      lock_limit: parseFloat(newData.find((item) => item.key === 'lock_limit')?.value) || 0,
      policy: newData.find((item) => item.key === 'policy')?.value || "",
      // Add other parameters here as needed
    };

  debugger;
  dispatch(updateSystemParameters(transformedValues))
  .then(() => {
    dispatch(getSystemParameters());
  })
  .catch((error) => {
    // Handle error if needed
  });
}
 }
 



  const setEditOptions=(value)=>{
    setEditedRow(value);
    setEditMode(true);
  }


    const columns = useMemo(
      () => [
        {
          accessorKey: 'description',
          header: t('Description'),
          type: 'text',
          mapped:true,
          required: true,
          size: 140,
        },
        {
          accessorKey: 'value',
          header: t('Value'),
          type: 'text',
          required: true,
          mapped:true,

          size: 140,
        },
        
      ],
      [data],
    );

  const RowActions =(row)=>{
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
          <Tooltip arrow placement="left" title={t("Edit")}>
            <IconButton onClick={() => {
              
              setEditOptions(row.original); }}>
              <Edit />
            </IconButton>
          </Tooltip>
  
        </Box>
      </>
    );
  }
  if(isLoading){
    return <Loader/>
  }
  return (
    <>
    <AwesomeTable 
    columns={columns}
    data={transformedData}
    RowActions={RowActions}
    showActions
    showTopActions={false}

    />
    <AwesomeDialog
      data = {editedRow}
      columns={columns}
      open={editMode}
      onClose={() => {
        setEditMode(false)
        setEditedRow()
      }}
      onSubmit={handleEdit}
    />
    </>
    
  )
}

export default SystemParameters