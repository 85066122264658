export const MRT_Localization_AR = {
  actions: "الإجراءات",
  and: "و",
  cancel: "إلغاء",
  changeFilterMode: "تغيير وضع التصفية",
  changeSearchMode: "تغيير وضع البحث",
  clearFilter: "مسح التصفية",
  clearSearch: "مسح البحث",
  clearSort: "مسح الترتيب",
  clickToCopy: "انقر للنسخ",
  collapse: "طي",
  collapseAll: "طي الكل",
  columnActions: "إجراءات العمود",
  copiedToClipboard: "تم النسخ إلى الحافظة",
  dropToGroupBy: "اسحب للتجميع حسب {column}",
  edit: "تعديل",
  expand: "توسيع",
  expandAll: "توسيع الكل",
  filterArrIncludes: "يحتوي",
  filterArrIncludesAll: "يحتوي على الكل",
  filterArrIncludesSome: "يحتوي على",
  filterBetween: "بين",
  filterBetweenInclusive: "بين بما في ذلك",
  filterByColumn: "تصفية حسب {column}",
  filterContains: "يحتوي على",
  filterEmpty: "فارغ",
  filterEndsWith: "ينتهي ب",
  filterEquals: "يساوي",
  filterEqualsString: "يساوي",
  filterFuzzy: "ضبابي",
  filterGreaterThan: "أكبر من",
  filterGreaterThanOrEqualTo: "أكبر من أو يساوي",
  filterInNumberRange: "بين",
  filterIncludesString: "يحتوي على",
  filterIncludesStringSensitive: "يحتوي على",
  filterLessThan: "أقل من",
  filterLessThanOrEqualTo: "أقل من أو يساوي",
  filterMode: "وضع التصفية: {filterType}",
  filterNotEmpty: "غير فارغ",
  filterNotEquals: "لا يساوي",
  filterStartsWith: "يبدأ ب",
  filterWeakEquals: "يساوي",
  filteringByColumn: "التصفية حسب {column} - {filterType} {filterValue}",
  goToFirstPage: "الانتقال إلى الصفحة الأولى",
  goToLastPage: "الانتقال إلى الصفحة الأخيرة",
  goToNextPage: "الانتقال إلى الصفحة التالية",
  goToPreviousPage: "الانتقال إلى الصفحة السابقة",
  grab: "اجتز",
  groupByColumn: "تجميع حسب {column}",
  groupedBy: "مجمع حسب",
  hideAll: "إخفاء الكل",
  hideColumn: "إخفاء عمود {column}",
  max: "الحد الأقصى",
  min: "الحد الأدنى",
  move: "نقل",
  noRecordsToDisplay: "لا توجد سجلات لعرضها",
  noResultsFound: "لم يتم العثور على نتائج",
  of: "من",
  or: "أو",
  pinToLeft: "تثبيت إلى اليسار",
  pinToRight: "تثبيت إلى اليمين",
  resetColumnSize: "إعادة تعيين حجم العمود",
  resetOrder: "إعادة تعيين الترتيب",
  rowActions: "إجراءات الصف",
  rowNumber: "#",
  rowNumbers: "أرقام الصفوف",
  rowsPerPage: "عدد الصفوف في الصفحة",
  save: "حفظ",
  search: "بحث",
  selectedCountOfRowCountRowsSelected:
    "{selectedCount} من {rowCount} صف (صفوف) محدد",
  select: "تحديد",
  showAll: "عرض الكل",
  showAllColumns: "عرض جميع الأعمدة",
  showHideColumns: "عرض/إخفاء الأعمدة",
  showHideFilters: "عرض/إخفاء التصفية",
  showHideSearch: "عرض/إخفاء البحث",
  sortByColumnAsc: "ترتيب حسب {column} تصاعديًا",
  sortByColumnDesc: "ترتيب حسب {column} تنازليًا",
  sortedByColumnAsc: "مرتب حسب {column} تصاعديًا",
  sortedByColumnDesc: "مرتب حسب {column} تنازليًا",
  thenBy: ", ثم بواسطة ",
  toggleDensity: "تبديل الكثافة",
  toggleFullScreen: "تبديل وضع ملء الشاشة",
  toggleSelectAll: "تبديل تحديد الكل",
  toggleSelectRow: "تبديل تحديد الصف",
  toggleVisibility: "تبديل الرؤية",
  ungroupByColumn: "إلغاء تجميع حسب {column}",
  unpin: "فك التثبيت",
  unpinAll: "إلغاء تثبيت الكل",
  unsorted: "غير مرتب",
};
