import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import AwesomeTable from '../../components/Awesome/AweomeTable';
import { Box, Button, Tooltip } from '@mui/material';
import { useState } from 'react';
import { createPayoffPayment, requiredMoneyFromAllDrivers } from '../../data/accounts/actions';
import { Lock, LockOpen } from '@mui/icons-material';
import AddPaymentDialog from './AddPaymentDialog';
import { t } from 'i18next';
import Loader from '../../components/Common/Loader';


function AccountsBalance() {
  const dispatch = useDispatch();
  const data  = useSelector((state)=> state.accounts);
  const [selectedRow, setSelectedRow] = useState();
  const [openView, setOpenView] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);


  useEffect(()=>{
    setIsLoading(true);

    dispatch(requiredMoneyFromAllDrivers()).then((res)=>{
      setIsLoading(false);

    });
  },[dispatch]);

  console.log(data)

  const onSubmit=(value)=>{
    var data = {
      driver_id: selectedRow.id,
      amount: +value.amount
    }
    dispatch(createPayoffPayment(data))
    .then(() => {
      dispatch(requiredMoneyFromAllDrivers());

    })
    .catch((error) => {
    });
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('Name'),
        type: 'text',
        required: false,
        size: 140,
      },
      {
        accessorKey: 'email',
        header: t('Email'),
        type: 'text',
        required: false,
        size: 140,
      },
      {
        accessorKey: 'phone',
        header: t('Phone'),
        type: 'text',
        required: false,
        size: 140,
      },
      {
        accessorKey: 'address',
        header: t('Address'),
        type: 'text',
        required: false,
        size: 140,
      },

      {
        accessorKey: 'amount',
        header: t('RequiredAmount'),
        type: 'text',
        required:false,
        size: 140
      },
      {
        accessorKey: 'wallet_status',
        header: t('AcountLocked'),
        type: 'text',
        required:false,
        size: 140,
        Cell: ({ row }) => (
          row.original.wallet_status ? 
          <Lock color='error'/>:<LockOpen color='success'/>
        ),
      },
      
    ],
    [data],
  );
  const setPaymentOptions=(value)=>{
    setSelectedRow(value);
    setOpenView(true);
  }
  const RowActions =(row)=>{
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>

          <Tooltip arrow placement="left" title={t("AddPayoff")}>
            <Button color="success" variant="contained" onClick={() => {
              
              setPaymentOptions(row.original); 
              
              }}>
                {t("AddPayoff")}
            </Button>
          </Tooltip>
       
        </Box>
      </>
    );
  }
  if(isLoading){
    return <Loader/>
  }
  return (
    <>
    <AwesomeTable 
    columns={columns}
    data={data?.data}
    showActions
    RowActions={RowActions}
    showTopActions ={false}

    />
   <AddPaymentDialog
      title={t("AddPayoff")}
      columns={[{accessorKey:"amount"}]}
      open={openView}
      onSubmit={onSubmit}
      onClose={() => {
        setOpenView(false)
        setSelectedRow()
      }}
    />

    {/* <ConfirmationDialog
      open={confirmationOpen}
      onClose={handleCloseConfirmation}
      onConfirm={setAcceptOptions}
      message="Are you sure you want to accept this request?"
      /> */}
      
    </>
  )
}

export default AccountsBalance