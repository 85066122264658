import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { t } from 'i18next';

function Transactions({ data }) {
  const theme = useTheme();
  const colors = theme.palette;

  if (!data || data.length === 0) {
    return (
      <Box p={2}>
        <Typography>{t("NoDataAvailable")}</Typography>
      </Box>
    );
  }

  return (
    <Box
      gridColumn="span 8"
      gridRow="span 4"
      bgcolor={colors.primary[400]}
      overflow="auto"
      borderRadius={4}
      p={2}
    >
      {data.map((transaction, i) => (
        <Box
          key={`${transaction.id}-${i}`}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`2px solid ${colors.success.main}`}
          color={colors.common.white}
          p={1}
        >
          <Box>
            <Typography
              color={colors.success.main}
              variant="h5"
              fontWeight="600"
            >
              {transaction.amount}
            </Typography>
            <Typography color={colors.common.black}>
              {transaction.status === "closed"? t("Closed"):  t("Pending")}
            </Typography>
          </Box>
          <Box color={colors.common.black}>{transaction.date}</Box>
          <Box
            bgcolor= {transaction.type === "debt"? colors.error.main: colors.success.main}
            p="5px 10px"
            borderRadius="4px"
            width={"10%"}
            textAlign={"center"}
            color={colors.common.white}
          >
            {transaction.type === "debt"? t("Debt"):  t("Payoff")}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default Transactions;
