const locale = {
  Trans: "منصة ترانز",
  Dashboard: "لوحة التحكم",
  DriversRequests: "طلبات السائقين",
  ProvidersRequests: "طلبات التجار",
  ActiveDrivers: "السائقين الفعالين",
  ActiveProviders: "التجار الفعالين",
  Accounts: "الحسابات",
  Orders: "الطلبات",
  TruckTypes: "أنواع الشاحنات",
  SystemParameters: "اعدادات النظام",
  SupportChats: "رسائل الدعم",
  UsersManagement: "ادارة المستخدمين",
  Data: "المعطيات",
  System: "النظام",
  Drivers: "السائقين",
  Providers: "المزودين",
  Clients: "العملاء",
  Invoices: "الفواتير",
  RecentOrders: "الطلبات الحديثة",
  View: "عرض",
  Accept: "قبول",

  Name: "الاسم",
  Email: "البريد الإلكتروني",
  Phone: "الهاتف",
  Address: "العنوان",
  HasTruck: "لديه شاحنة",
  ProfileImageUrl: "صورة الملف الشخصي",
  IdImage: "صورة الهوية",
  TruckImage: "صورة الشاحنة",
  CompanyImage: "صورة الشركة",
  TruckName: "اسم الشاحنة",
  LoadByTon: "الحمولة بالطن",
  PlateNumber: "رقم اللوحة",
  Symbol: "الرمز",
  Governorate: "المحافظة",
  companyName: "اسم الشركة",
  companyPhone: "رقم هاتف الشركة",
  ConfirmationAcceptRequest: "هل أنت متأكد أنك تريد قبول هذا الطلب؟",
  ConfirmationDeleteRequest: "هل أنت متأكد أنك تريد حذف هذا الطلب؟",
  DeactivateRequest: "هل أنت متأكد أنك تريد إلعاء تفعيل هذا المستخدم",
  NoDataAvailable: "لا يوجد بيانات لعرضها",
  Debt: "دفعة توصيل",
  Payoff: "دفعة للشركة",
  OldPasswordIsRequired: "كلمة المرور القدية مطلوبة",
  NewPasswordIsRequired: "كلمة المرور الجديدة مطلوبة",
  MustBeValidEmail: "يجب أن يكون بريداً صالحاً",
  EmailIsRequired: "البريد الاكترني مطلوب",
  Closed: "مغلقة",
  Confirmation: "تأكيد",
  Cancel: "إلغاء",
  NewPassword: "كلمة المرور الجديدة",
  OldPassword: "كلمة المرور القديمة",
  Confirm: "تأكيد",
  Profile: "الملف الشخصي",
  Close: "إغلاق",
  Deactivate: "الغاء التفعيل",
  Transactions: "عمليات الدفع",
  AddPayoff: "أضف دفعة",
  AcountLocked: "حالة الحساب",
  RequiredAmount: "المبلغ المطلوب",

  OrderNumber: "رقم الطلب",
  ProviderName: "اسم المزود",
  ClientName: "اسم العميل",
  ClientPhone: "هاتف العميل",
  TotalCost: "التكلفة الإجمالية",
  Date: "التاريخ",

  CreatedAt: "تاريخ الإنشاء",
  ProductsPrice: "سعر المنتجات",
  TransportCost: "تكلفة النقل",
  Provider: "المزود",

  Client: "العميل",
  Company: "الشركة",
  Products: "المنتجات",
  Price: "السعر",
  Quantity: "الكمية",
  Trucks: "الشاحنات",
  Image: "الصورة",
  Cost: "التكلفة",
  RequestCountsByStatus: "عدد الطلبات حسب الحالة",
  Count: "العدد",
  DriverNames: "أسماء السائقين",

  DeleteConfirmation: "هل أنت متأكد أنك تريد حذف هذا؟",
  Status: "الحالة",
  deleted: "محذوف",
  Edit: "تعديل",
  Delete: "حذف",
  Description: "الوصف",
  Value: "القيمة",
  Create: "إنشاء",
  TypeAMessage: "اكتب رسالة",
  Chats: "الدردشات",
  ChatMessages: "رسائل الدردشة",
  RecordingInProgress: "جارٍ التسجيل",
  Continue: "متابعة",
  Login: "تسجيل الدخول",
  Password: "كلمة المرور",
};
export default locale;
