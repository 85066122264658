import * as Actions from "../actions";

const initState = {};

const counterReducer = (state = initState, action) => {
  switch (action.type) {
    case Actions.TEST: {
      // code

      break;
    }

    default:
      return state;
  }
};

export default counterReducer;
