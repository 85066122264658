import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, DiractionModeContext, useMode } from "./theme";
import Login from "./app/pages/Login";
import TruckTypes from "./app/pages/truckTypes";
import {
  RequireAuth,
  useAuthHeader,
  useAuthUser,
  useIsAuthenticated,
} from "react-auth-kit";
import DriversRequests from "./app/pages/driversRequests";
import AccountsBalance from "./app/pages/accounting";
import OrdersOverView from "./app/pages/orders";
import SystemParameters from "./app/pages/systemParameters";
import NewDashboard from "./app/pages/dashboard";
import SupportChat from "./app/pages/chats/supportChat";
import AcceptedDrivers from "./app/pages/acceptedDrivers";
import ProvidersRequests from "./app/pages/providersRequests";
import AcceptedProviders from "./app/pages/acceptedProviders";
import { onMessageListener } from "./firebaseInit";
import axios from "axios";
import Notifications from "./app/components/Notifications/Notifications";
import ReactNotificationComponent from "./app/components/Notifications/ReactNotification";
import Profile from "./app/pages/profile";

function App() {
  const [theme, colorMode, directionMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const isAuthenticated = useIsAuthenticated();
  const authHeader = useAuthHeader();

  const setAuthHeader = (config) => {
    config.headers["Authorization"] = authHeader();
    return config;
  };

  axios.interceptors.request.use(
    (config) => setAuthHeader(config),
    (error) => Promise.reject(error)
  );

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  console.log(show, notification);

  useEffect(() => {
    const messageListener = async () => {
      try {
        const payload = await onMessageListener();
        setShow(true);
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
        console.log(payload);
      } catch (err) {
        console.log("failed: ", err);
      }
    };

    messageListener();
  }, []);

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <DiractionModeContext.Provider value={directionMode}>
            <div className="app" style={{ direction: theme.direction }}>
              {!isAuthenticated() ? (
                <Login />
              ) : (
                <>
                  {isSidebar && <Sidebar isSidebar={isSidebar} />}
                  <main className="content">
                    <Topbar setIsSidebar={setIsSidebar} />
                    <Notifications />

                    <Routes>
                      <Route path="/login" element={<Login />} />

                      <Route
                        path="/"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <NewDashboard />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/truckTypes"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <TruckTypes />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/drivers-request"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <DriversRequests />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/providers-request"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <ProvidersRequests />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/active-drivers"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <AcceptedDrivers />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/active-providers"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <AcceptedProviders />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/drivers-accounts"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <AccountsBalance />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/orders"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <OrdersOverView />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/system-parameters"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <SystemParameters />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/chats"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <SupportChat />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/profile"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <Profile />
                          </RequireAuth>
                        }
                      />
                    </Routes>
                  </main>
                </>
              )}
            </div>
          </DiractionModeContext.Provider>
        </ThemeProvider>
      </ColorModeContext.Provider>

      {show && (
        <ReactNotificationComponent
          title={notification.title}
          body={notification.body}
        />
      )}
    </>
  );
}

export default App;
