import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItemText,
  ListItem,
  useTheme,
  Chip,
} from "@mui/material";
import { t } from "i18next";
import { tokens } from "../../../theme";

const OrderInvoice = ({ order }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!order) {
    return <Typography>{t("NoDataAvailable")}</Typography>;
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      key={order.id}
      dir={theme.direction}
      color={colors.primary[600]}
    >
      <Card
        sx={{
          marginBottom: theme.spacing(4),
          // backgroundColor: colors.primary[600],
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: theme.spacing(2),
          padding: theme.spacing(2),
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            sx={{
              marginBottom: theme.spacing(2),
              fontWeight: "bold",
              borderBottom: `2px solid ${colors.greenAccent[500]}`,
            }}
          >
            {t("OrderNumber")}: {order.order_number}
          </Typography>
          <Typography color="textSecondary">
            {t("CreatedAt")}: {order.created_at}
          </Typography>
          <Typography color="textSecondary">
            {t("ProductsPrice")}: {order.invoice.products_price}
          </Typography>
          <Typography color="textSecondary">
            {t("TransportCost")}: {order.invoice.transport_cost}
          </Typography>
          <Typography color="textSecondary">
            {t("TotalCost")}: {order.invoice.total_cost}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              marginBottom: theme.spacing(2),
              fontWeight: "bold",
              borderBottom: `2px solid ${colors.greenAccent[500]}`,
            }}
          >
            {t("Provider")}
          </Typography>
          <List disablePadding dir={theme.direction}>
            <ListItem
              sx={{
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                textAlign: theme.direction === "rtl" ? "right" : "left",
              }}
            >
              <ListItemText
                primary={t("Name")}
                secondary={order.provider.name}
              />
            </ListItem>
            <ListItem
              sx={{
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                textAlign: theme.direction === "rtl" ? "right" : "left",
              }}
            >
              <ListItemText
                primary={t("Address")}
                secondary={order.provider.address}
              />
            </ListItem>
            <ListItem
              sx={{
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                textAlign: theme.direction === "rtl" ? "right" : "left",
              }}
            >
              <ListItemText
                primary={t("Phone")}
                secondary={order.provider.phone || t("N/A")}
              />
            </ListItem>
          </List>
          <Typography
            variant="h6"
            sx={{
              marginBottom: theme.spacing(2),
              fontWeight: "bold",
              borderBottom: `2px solid ${colors.greenAccent[500]}`,
            }}
          >
            {t("Client")}
          </Typography>
          <List disablePadding>
            <ListItem
              sx={{
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                textAlign: theme.direction === "rtl" ? "right" : "left",
              }}
            >
              <ListItemText primary={t("Name")} secondary={order.client.name} />
            </ListItem>
            <ListItem
              sx={{
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                textAlign: theme.direction === "rtl" ? "right" : "left",
              }}
            >
              <ListItemText
                primary={t("Phone")}
                secondary={order.client.phone}
              />
            </ListItem>
            <ListItem
              sx={{
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                textAlign: theme.direction === "rtl" ? "right" : "left",
              }}
            >
              <ListItemText
                primary={t("Company")}
                secondary={order.client.company_name}
              />
            </ListItem>
            <ListItem
              sx={{
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                textAlign: theme.direction === "rtl" ? "right" : "left",
              }}
            >
              <ListItemText
                primary={t("Address")}
                secondary={order.client.address}
              />
            </ListItem>
          </List>
          <Typography
            variant="h6"
            sx={{
              marginBottom: theme.spacing(2),
              fontWeight: "bold",
              borderBottom: `2px solid ${colors.greenAccent[500]}`,
            }}
          >
            {t("Products")}
          </Typography>
          <List disablePadding>
            {order.products.map((product, index) => (
              <ListItem
                key={index}
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  borderBottom: `1px solid ${theme.palette.grey[300]}`,
                  textAlign: theme.direction === "rtl" ? "right" : "left",
                }}
              >
                <ListItemText
                  primary={product.name}
                  secondary={`${t("Price")}: ${product.price} | ${t(
                    "Quantity"
                  )}: ${product.quantity}`}
                />
              </ListItem>
            ))}
          </List>
          <Typography
            variant="h6"
            sx={{
              marginBottom: theme.spacing(2),
              fontWeight: "bold",
              borderBottom: `2px solid ${colors.greenAccent[500]}`,
            }}
          >
            {t("Trucks")}
          </Typography>
          <List disablePadding>
            {order.trucks.map((truck, index) => (
              <ListItem
                key={index}
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  borderBottom: `1px solid ${theme.palette.grey[300]}`,
                  textAlign: theme.direction === "rtl" ? "right" : "left",
                }}
              >
                <ListItemText
                  primary={truck.name}
                  secondary={`${t("Cost")}: ${truck.cost} | ${t("Quantity")}: ${
                    truck.quantity
                  }`}
                />
              </ListItem>
            ))}
          </List>
          {/* Display requestsCountsByStatus */}
          {order.status == "deleted" ? (
            <>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: theme.spacing(2),
                  fontWeight: "bold",
                  borderBottom: `2px solid ${colors.greenAccent[500]}`,
                }}
              >
                {t("Status")}
              </Typography>
              <Chip label={t(order.status)} color="error" variant="outlined" />
            </>
          ) : (
            <>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: theme.spacing(2),
                  fontWeight: "bold",
                  borderBottom: `2px solid ${colors.greenAccent[500]}`,
                }}
              >
                {t("RequestCountsByStatus")}
              </Typography>
              <List disablePadding>
                {Object.entries(order.requestsCountsByStatus).map(
                  ([status, count], index) => (
                    <ListItem
                      key={index}
                      sx={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderBottom: `1px solid ${theme.palette.grey[300]}`,
                        textAlign: theme.direction === "rtl" ? "right" : "left",
                      }}
                    >
                      <ListItemText
                        primary={status}
                        secondary={`${t("Count")}: ${count}`}
                      />
                    </ListItem>
                  )
                )}
              </List>
              {/* Display driverNames */}
              <Typography
                variant="h6"
                sx={{
                  marginBottom: theme.spacing(2),
                  fontWeight: "bold",
                  borderBottom: `2px solid ${colors.greenAccent[500]}`,
                }}
              >
                {t("DriverNames")}
              </Typography>
              <List disablePadding>
                {order.driverNames.map((driverName, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      borderBottom: `1px solid ${theme.palette.grey[300]}`,
                      textAlign: theme.direction === "rtl" ? "right" : "left",
                    }}
                  >
                    <ListItemText primary={driverName} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OrderInvoice;
