import { Box, IconButton, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications } from '../../data/home/actions';
import Loader from '../../components/Common/Loader';

function NotificationsList() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const data = useSelector((state) => state.home.notifications);
    const [notifications, setNotifications] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (open) {
            setIsLoading(true);
            dispatch(getNotifications()).then((res)=>{
            setIsLoading(false);

            });
        }
    }, [dispatch, open]);

    useEffect(() => {
        setNotifications(data?.data ?? [])
    }, [data]);

    const handleIconClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const popoverStyle = {
        padding: '16px',
        minWidth: '200px',  
        minHeight: '150px',
    };

    const listItemStyle = {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        '&:lastChild': {
            borderBottom: 'none',
        },
        direction: "rtl"
    };

    const listItemTextStyle = {
        display: 'flex',
        alignItems: 'center',
    };

    const iconStyle = {
        marginRight: '8px',
        color: '#1976d2', // Adjust color as needed
    };

    return (
        <div>
            <IconButton onClick={handleIconClick}>
                <NotificationsOutlinedIcon />
            </IconButton>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                style={popoverStyle}
            >
                {isLoading? (
                <Box
                sx={{minWidth:'200px',
                 minHeight:"200px",
                 display:'flex',
                 justifyContent:'center',
                 alignItems:'center' 
                
                }}
                
                >

                    <Loader/>
                </Box>
                
                ):(
                <List 
                sx={{minWidth:'200px',maxHeight:"300px"}}
                
                >
                {notifications.map((notification) => (
                    <ListItem key={notification.id} style={listItemStyle}>
                        <ListItemText
                            primary={
                                <Typography variant="body2" style={listItemTextStyle}>
                                    <NotificationsOutlinedIcon style={iconStyle} />
                                    {notification.body}
                                </Typography>
                            }
                            secondary={
                                <Typography variant='subtitle2' sx={{fontStyle:'italic'}}>

                                    {notification.created_at}
                                </Typography>
                            }
                        />
                    </ListItem>
                ))}
                </List>
            )}
             
            </Popover>
        </div>
    );
}

export default NotificationsList;
