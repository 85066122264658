import { Box, Typography, TextField, IconButton, Paper } from "@mui/material";
import { Send as SendIcon, Mic as MicIcon, StopCircleOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import axios from "axios";
import { useRef } from "react";
import { t } from "i18next";
import { tokens } from "../../../theme";
import { useTheme } from "@emotion/react";

const SupportChat = () => {
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [audioFile, setAudioFile] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const messagesEndRef = useRef(null);
  const api = process.env.REACT_APP_API_URL;
  const  files = process.env.REACT_APP_CHATS ;
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  // Fetch chat channels
  useEffect(() => {
    const url = `${api}/dashboard/supportChat`;
    axios.get(url).then((response) => {
      setChannels(response.data);
    });
  }, []);

  // Fetch chat messages when a channel is selected
  useEffect(() => {
    getMessages()
  }, [selectedChannel]);

 const getMessages = ()=>{
  if (selectedChannel) {
    var url = `${api}/dashboard/supportChat/getMessages?channel_id=${selectedChannel.id}`;

    axios.get(url).then((response) => {
      setMessages(response.data.data);
    });
  }
  }

  // Function to send a message
  const sendMessage = () => {
    debugger;
    if (isRecording) {
      // If recording is in progress, stop the recording before sending the message
      stopRecording();
    }

  // Check if the message is a text message or an audio file
  if (messageText.trim() === "" && !audioFile) {
    return;
  }



    const formData = new FormData();
    const newMessage={
      message:  audioFile !== null ? audioFile : messageText,
      message_type: audioFile instanceof Blob ? 'file' : 'text',
    }
 // Add text message to the form data
 if (messageText.trim() !== "") {
  formData.append("message", messageText);
}
  // Add audio file to the form data if available
  if (audioFile) {
    formData.append("message", audioFile, "audio.wav");
  }

    // Add any other necessary data to the form data
    formData.append("sender_type", "admin");


    var url = `${api}/dashboard/supportChat/sendMessage?channel_id=${selectedChannel.id}`;

    // Send message to the backend API
    axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        // Update the messages state with the new message
        // Clear the message input
        setMessageText("");
        setAudioFile(null);
        if(newMessage.message_type==='file'){
          getMessages();
        }else{
          setMessages((prevMessages) => [...prevMessages, newMessage]);

        }
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      })
      .catch((error) => {
        console.error("Error sending the message:", error);
      });
  };

  const startRecording = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error("getUserMedia is not supported in this browser");
      return;
    }
  
    setIsRecording(true);
    chunksRef.current = [];
    
    const handleSuccess = (stream) => {
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunksRef.current.push(e.data);
        }
      };
      mediaRecorderRef.current.onstop = handleStop;
      mediaRecorderRef.current.start();
    };
    
    const handleError = (error) => {
      console.error("Error accessing the microphone:", error);
      setIsRecording(false);
    };
  
    navigator.mediaDevices.getUserMedia({ audio: true }).then(handleSuccess).catch(handleError);
  };
  
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };
  
  const handleStop = () => {
    const recordedBlob = new Blob(chunksRef.current, { type: "audio/wav" });
    setIsRecording(false);
  
       // Set the audioFile state with the recorded audio blob
       setAudioFile(recordedBlob);
       sendMessage();
  
    chunksRef.current = [];
  };
  

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="80%"
      width="100%"
      margin="5px"
      borderRadius="16px"
      component={Paper}
    >
      <Box display="flex" flexDirection="row" height="100%">
        {/* Channel List */}
        <Box width={250} bgcolor={colors.primary[400]}  p={2} sx={{ boxShadow: "4px 0 4px -4px rgba(0, 0, 0, 0.1)" }}>
  <Typography variant="h6" gutterBottom>
    {t("Chats")}
  </Typography>
  {channels.map((channel) => (
    <Box
      key={channel.id}
      display="flex"
      alignItems="center"
      mb={1}
      bgcolor={selectedChannel?.id === channel.id ? colors.primary : "transparent"}
      cursor="pointer"
      onClick={() => setSelectedChannel(channel)}
      p={1}
      sx={{
        borderLeft: `4px solid ${selectedChannel?.id === channel.id ? "green" : "transparent"}`,
        "&:hover": {
          bgcolor: "#d0d0d0",
        },
        borderBottom: "1px solid #e0e0e0",
        borderRadius: 0,
        margin: 0,
      }}
    >
      <Typography>{channel.user.name}</Typography>
    </Box>
  ))}
</Box>



        {/* Chat Messages */}
        <Box flex={1} p={2}
        bgcolor={colors.background}
         display="flex" flexDirection="column">
          <Typography variant="h6" gutterBottom>
            {t("ChatMessages")}
          </Typography>
          <Box flex={1} overflow="auto">
          {messages?.map((message, index) => (
  <Box
    key={index}
    bgcolor={message.sender_type === "admin" ? "#f0f0f0" : "#e0e0e0"}
    borderRadius={4}
    p={1}
    mb={1}
    alignSelf={message.sender_type === "user" ? "flex-start" : "flex-end"}
    maxWidth="60%"
    marginRight={
      message.sender_type === "user" || message.message_type === "file" ? 0 : "auto"
    }
    marginLeft={
      message.sender_type === "user" || message.message_type === "file" ? "auto" : 0
    }
  >
    {message.message_type === "file" ? (
        <audio controls>
          <source src={`${files}/${message.message}`} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>
    ) : (
      <>
        <Typography variant="body1">{message.message}</Typography>
        {/* <Typography variant="caption">{message.created_at}</Typography> */}
      </>
    )}
  </Box>
))}

        <div ref={messagesEndRef} />
        </Box>
        {/* Message Input */}
<Box display="flex" alignItems="center" mt={2}>
  {isRecording ? (
    <>
      <Typography variant="body1">{t("RecordingInProgress")}...</Typography>
      <IconButton color="secondary" onClick={stopRecording}>
        <StopCircleOutlined />
      </IconButton>
      <IconButton color="primary" onClick={sendMessage} disabled>
        <SendIcon />
      </IconButton>
    </>
  ) : (
    <>
      <TextField
        label={t("TypeAMessage")}
        variant="outlined"
        fullWidth
        value={messageText}
        onChange={(e) => setMessageText(e.target.value)}
      />
      <IconButton color="primary" onClick={sendMessage} disabled={!selectedChannel}>
        <SendIcon />
      </IconButton>
      <IconButton color="secondary" onClick={startRecording}>
        <MicIcon />
      </IconButton>
    </>
  )}
</Box>

        </Box>
      </Box>
    </Box>
  );
};

export default SupportChat;
