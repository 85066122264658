import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import React from 'react';
import OrderInvoice from './orderInvoice';
import { t } from 'i18next';
import { useTheme } from '@emotion/react';

function OrderDialog({ order,title ,open,  onClose }) {



  const theme = useTheme();

  return (
    <Dialog open={open} dir={theme.direction} >
      <DialogTitle textAlign="center">{title}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '400px', sm: '460px', md: '500px' },
            gap: '1.5rem',
          }}
        >

        <OrderInvoice  order={order}  />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button color='inherit' onClick={onClose} >
          {t("Close")}
        </Button>

      </DialogActions>
    </Dialog>
  );
}

export default OrderDialog;
