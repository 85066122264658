import axios from "axios";

export const GET_HOME_DATA = "[HOME APP] GET_HOME_DATA";
export const UPDATE_FCM = "[HOME APP] UPDATE_FCM";
export const GET_NOTIFICATIONS = "[HOME APP] GET_NOTIFICATIONS";
export const GET_LOGGED_ADMIN = "[HOME APP] GET_LOGGED_ADMIN";
export const UPDATE_PROFILE = "[HOME APP] UPDATE_PROFILE";

const api = process.env.REACT_APP_API_URL;

export function getHomeData() {
  const url = `${api}/dashboard/home`;
  return (dispatch) => {
    const request = axios.get(url);
    return request.then((response) =>
      dispatch({
        type: GET_HOME_DATA,
        payload: response.data,
      })
    );
  };
}
export function storeFcmToken(data) {
  const url = `${api}/dashboard/storeFcmToken`;
  return (dispatch) => {
    const request = axios.post(url, data);
    return request.then((response) =>
      dispatch({
        type: GET_HOME_DATA,
        // payload: response.data,
      })
    );
  };
}
export function getNotifications() {
  const url = `${api}/dashboard/notifications`;
  return (dispatch) => {
    const request = axios.get(url);
    return request.then((response) =>
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: response.data,
      })
    );
  };
}
export function getLoggedAdmin() {
  const url = `${api}/dashboard/loggedUser`;
  return (dispatch) => {
    const request = axios.get(url);
    return request.then((response) =>
      dispatch({
        type: GET_LOGGED_ADMIN,
        payload: response.data,
      })
    );
  };
}
export function updateAdminProfile(data) {
  const url = `${api}/dashboard/updateProfile`;
  return (dispatch) => {
    const request = axios.post(url, data);
    return request.then((response) =>
      dispatch({
        type: UPDATE_PROFILE,
        payload: response.data,
      })
    );
  };
}
